const debug = false;

export function log(message: string[]|string, predicate = false) {
    if (debug || predicate) {
        if (Array.isArray(message)) {
            console.log.apply(null, message);
        } else {
            console.log(message);
        }
    }
}

export function slugify(str: string): string {
    return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
}

export function getPropertyValue(el: HTMLElement, name: string): string|null {
    if(!el) return null;
    const prop = window.getComputedStyle(el).getPropertyValue(name);
    return prop || null;
}

export function setPropertyValue(el: HTMLElement, name: string, value: string) {
    el.style.setProperty(`--${name}`, value);
}

/**
 * Triggers Matomo event if Matomo is available and tracking activated
 */
export function triggerMatomoEvent(trigger: string[], tracking = true) {
    // @ts-ignore
    if (tracking && undefined !== _paq) {
        // @ts-ignore
        _paq.push(trigger);
    } else {
        log(['Triggered w/o tracking: ', trigger.join(' -> ')]);
    }
}
