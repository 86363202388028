import './app.ts';
import {getPropertyValue, log, triggerMatomoEvent} from "@scripts/utils.ts";

const tracking = true && import.meta.env.PROD;

let lastScrollPosition: number = 0;
let scrollTimer: any = null;

function scrollPosition() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

// Start page life-cycle
document.addEventListener("readystatechange", () => {
    switch (document.readyState) {
        case 'interactive':
            log('##### Page Ready #####');
            onPageReady();
            break;
        case 'complete':
            log('##### Page Load Complete #####');
            onPageLoaded();
            break;
    }
});

document.addEventListener("scroll", () => {
    if (scrollPosition() === lastScrollPosition) return;

    const scrollEvent = (scrollPosition() > lastScrollPosition) ? 'scrollDown' : 'scrollUp';
    document.dispatchEvent(new Event(scrollEvent));

    if (scrollTimer) clearTimeout(scrollTimer);
    scrollTimer = setTimeout(() => document.dispatchEvent(new Event('scrollEnd')), 250);
    lastScrollPosition = scrollPosition();
});

document.addEventListener("scrollUp", () => {
    log('Scroll Up');
});

document.addEventListener("scrollDown", () => {
    log('Scroll Down');
});

document.addEventListener("scrollEnd", () => {
    log('Scroll End');
});

function onPageReady() {
    log('++++ Page Ready ++++');
}

function onPageLoaded() {
    log('++++ Page Loaded ++++');

    document.body.classList.add('loaded');

    document.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        if (target.tagName.toLowerCase() === 'a') {
            // Handle Matomo click events
            if (target.dataset.mtmEvent) {
                const mtmNamespace: string = "Bianca Diehl";
                const mtmEvent: string = target.dataset.mtmEvent;
                const mtmCategory: string = 'Click Link';

                triggerMatomoEvent(['trackEvent', mtmNamespace, mtmCategory, mtmEvent], tracking);
            }

            // Handle fade navigation
            const bodyPageTransition = getPropertyValue(document.body, '--page-transition-timeout').replace(/[^\d.-]/g, '');
            if (bodyPageTransition && document.body.classList.contains('loaded')) {
                const link = target.closest('a');
                const href = link.href;
                const linkTarget = link.getAttribute('target');
                const external = linkTarget === '_blank' || isExternal(href) || checkIfFile(href);
                const isAnchor = !isExternal(href) && href.indexOf("#") !== -1;

                if (href && !external && !isAnchor) {
                    e.preventDefault();

                    document.body.classList.remove('loaded');
                    // @ts-ignore
                    window.setTimeout(() => window.location = href, bodyPageTransition < 100 ? bodyPageTransition * 100 : bodyPageTransition);
                }
            }
        }
    });
}

function checkIfFile(path: string) {
    const url = new URL(path);
    if (url.pathname.indexOf('@') !== -1) return false;
    return url.pathname.split('/').pop().indexOf('.') > 0;
}

function isExternal(path: string) {
    const url = new URL(path);
    return url.origin !== location.origin;
}

function checkLinkClick(event: Event) {
    const target = event.target as HTMLElement;
    const link = target.closest('a');
    if (!link || !link.href) return;

    const href = link.href;
    // const url = new URL(href);
    const linkTarget = link.getAttribute('target');
    const isFile = checkIfFile(href);
    const isAnchor = !isExternal(href) && href.indexOf("#") != -1;
    const external = linkTarget === '_blank' || isExternal(href) || isFile;

    const detail = {
        scope: external ? 'external' : 'internal',
        isFile: isFile,
        isMailTo: href.startsWith('mailto:'),
        isTel: href.startsWith('tel:'),
        isAnchor: isAnchor
    }
    // console.log(detail);

    target.dispatchEvent(new CustomEvent('linkClick', { detail }));
}